var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "clearflex" },
        [
          _c(
            "el-button",
            {
              staticClass: "fl-right",
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.authApiEnter },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-tree", {
        ref: "apiTree",
        attrs: {
          data: _vm.apiTreeData,
          "default-checked-keys": _vm.apiTreeIds,
          props: _vm.apiDefaultProps,
          "default-expand-all": "",
          "highlight-current": "",
          "node-key": "onlyId",
          "show-checkbox": "",
        },
        on: { check: _vm.nodeChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }